import { defineStore } from 'pinia';
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from '#root/shared/config/subscriptions-config';
import type {
  Subscription,
  CartSubscriptionItem,
} from '#root/shared/types/subscription-types';

export const useSubscriptionStore = defineStore('subscription', () => {
  const nuxtApp = useNuxtApp();

  const subscriptionProductsInStore = ref<CartSubscriptionItem[]>([]);
  const enabledFeaturesStore = useEnabledFeaturesStore();
  const subscriptionLocalStorageKey = 'subscriptionProducts';
  const subscription = ref<Subscription | null>(null);

  type HeadersType = {
    [key: string]: string;
  };

  const loadSubscriptionProducts = () => {
    if (typeof localStorage === 'undefined') {
      // Mock localStorage if not available
      return [];
    }
    // Get subscription products from local storage
    const subscriptionProductsString = localStorage.getItem(
      subscriptionLocalStorageKey
    );

    const parsedSubscriptionProducts = subscriptionProductsString
      ? (JSON.parse(subscriptionProductsString) as CartSubscriptionItem[])
      : [];

    // Set subscription products in store
    subscriptionProductsInStore.value = parsedSubscriptionProducts;

    return parsedSubscriptionProducts;
  };

  const subscriptionProducts = computed(() => {
    if (enabledFeaturesStore.isFeatureEnabled('subscriptions')) {
      return subscriptionProductsInStore.value.length
        ? subscriptionProductsInStore.value
        : loadSubscriptionProducts();
    }
    return [];
  });

  // Local storage accessors

  const getSubscriptionProductsFromLocalStorage = () => {
    // Get existing subscription products from localStorage
    const subscriptionProductsJSON = localStorage.getItem(
      subscriptionLocalStorageKey
    );

    if (subscriptionProductsJSON !== null) {
      return JSON.parse(subscriptionProductsJSON);
    }

    return [];
  };

  const setSubscriptionProductsFromLocalStorage = (
    subscriptionProduct: CartSubscriptionItem[]
  ) => {
    const updatedSubscriptionProducts = JSON.stringify(subscriptionProduct);
    localStorage.setItem(
      subscriptionLocalStorageKey,
      updatedSubscriptionProducts
    );
  };

  const addSubscriptionProductToLocalstorage = (
    subscriptionProduct: CartSubscriptionItem
  ) => {
    // Add subscription product to array of subscriptions products in localStorage

    // Get existing subscription products from localStorage
    const existingSubscriptionProducts: CartSubscriptionItem[] =
      getSubscriptionProductsFromLocalStorage();

    const foundSubscriptionProduct = existingSubscriptionProducts.find(
      (prod) =>
        prod.productId === subscriptionProduct.productId &&
        prod.variantId === subscriptionProduct.variantId &&
        prod.frequencyInDays === subscriptionProduct.frequencyInDays
    );

    if (foundSubscriptionProduct) {
      return updateSubscriptionProductQuantity(
        subscriptionProduct.productId,
        subscriptionProduct.variantId,
        subscriptionProduct.frequencyInDays,
        foundSubscriptionProduct.quantity + 1
      );
    }

    // Add the new subscription product
    existingSubscriptionProducts.push(subscriptionProduct);

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const updateSubscriptionProductQuantityToLocalstorage = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number,
    newQuantity: number
  ) => {
    // Update the quantity of a subscription product present in the array of subscriptions products in localStorage

    if (newQuantity === 0) {
      return removeSubscriptionProductFromLocalstorage(
        productId,
        variantId,
        frequencyInDays
      );
    }

    // Get existing subscription products from localStorage
    const existingSubscriptionProducts: CartSubscriptionItem[] =
      getSubscriptionProductsFromLocalStorage();

    const subscriptionProductIndex = existingSubscriptionProducts.findIndex(
      (product) =>
        product.productId === productId &&
        product.variantId === variantId &&
        product.frequencyInDays === frequencyInDays
    );

    const currentSubscriptionProduct =
      existingSubscriptionProducts[subscriptionProductIndex];

    if (currentSubscriptionProduct) {
      currentSubscriptionProduct.quantity = newQuantity;
      const updatedProduct = updateProductWithTotalPrices(
        currentSubscriptionProduct
      );
      existingSubscriptionProducts[subscriptionProductIndex] = updatedProduct;
    }

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const removeSubscriptionProductFromLocalstorage = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number
  ) => {
    // Removes a subscription product present in the array of subscriptions products in localStorage

    // Get existing subscription products from localStorage
    let existingSubscriptionProducts: CartSubscriptionItem[] =
      getSubscriptionProductsFromLocalStorage();

    existingSubscriptionProducts = existingSubscriptionProducts.filter(
      (product) =>
        !(
          product.productId === productId &&
          product.variantId === variantId &&
          product.frequencyInDays === frequencyInDays
        )
    );

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const addSubscriptionProductToCart = (product: CartSubscriptionItem) => {
    const searchedIndex = subscriptionProductsInStore.value.findIndex(
      (prod) =>
        prod.productId === product.productId &&
        prod.variantId === product.variantId &&
        prod.frequencyInDays === product.frequencyInDays
    );

    // This is a safety in case this function is called after a product update
    if (searchedIndex !== -1) return;

    subscriptionProductsInStore.value = [
      ...subscriptionProductsInStore.value,
      product,
    ];
  };

  const updateSubscriptionProductsInCart = (
    updatedSubscriptionProducts: CartSubscriptionItem[]
  ) => {
    subscriptionProductsInStore.value = updatedSubscriptionProducts;
  };
  const addSubscriptionProduct = (
    product: Omit<CartSubscriptionItem, 'prices'>
  ) => {
    const updatedProduct = updateProductWithTotalPrices(product);
    addSubscriptionProductToLocalstorage(updatedProduct);
    addSubscriptionProductToCart(updatedProduct);
  };

  const updateSubscriptionProductQuantity = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number,
    quantity: number
  ) =>
    updateSubscriptionProductsInCart(
      updateSubscriptionProductQuantityToLocalstorage(
        productId,
        variantId,
        frequencyInDays,
        quantity
      )
    );

  const removeSubscriptionProduct = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number
  ) =>
    updateSubscriptionProductsInCart(
      removeSubscriptionProductFromLocalstorage(
        productId,
        variantId,
        frequencyInDays
      )
    );

  // Using subscriptionProductsInStore to avoid showing potentially outdated data from localStorage after cart clear
  const subscriptionItemCount = computed(
    () =>
      subscriptionProductsInStore.value.reduce(
        (acc: number, curr: { quantity: number }) => acc + curr.quantity,
        0
      ) || 0
  );

  const subscriptionBaseAmount = computed(
    () =>
      subscriptionProducts?.value.reduce(
        (total: number, product: CartSubscriptionItem) =>
          total + (product.prices?.basePrice.value ?? 0),
        0
      ) || 0
  );

  const subscriptionAmount = computed(
    () =>
      subscriptionProducts?.value.reduce(
        (total: number, product: CartSubscriptionItem) =>
          total + (product.prices?.salePrice.value ?? 0),
        0
      ) || 0
  );
  const subscriptionCampaignAmount = computed(
    () => subscriptionAmount.value - subscriptionBaseAmount.value || 0
  );

  const updateProductWithTotalPrices = (
    product: Omit<CartSubscriptionItem, 'prices'>
  ): CartSubscriptionItem => {
    const prices = {
      ...product.unitPrices,
      basePrice: {
        ...product.unitPrices.basePrice,
        value: product.unitPrices.basePrice.value * product.quantity,
      },
      price: {
        ...product.unitPrices.price,
        value: product.unitPrices.price.value * product.quantity,
      },
      salePrice: {
        ...product.unitPrices.salePrice,
        value:
          (product.unitPrices.salePrice?.value ??
            product.unitPrices.basePrice.value) *
          product.quantity *
          ((100 - SUBSCRIPTION_DISCOUNT_PERCENTAGE) / 100),
      },
    };

    return { ...product, prices };
  };

  const clearCart = () => {
    localStorage.setItem(subscriptionLocalStorageKey, JSON.stringify([]));
    subscriptionProductsInStore.value = [];
  };

  const subscriptionDiscountAmount = computed(() => {
    // Calculated for now with 10% discount
    const discountAmount =
      subscriptionAmount.value * (SUBSCRIPTION_DISCOUNT_PERCENTAGE / 100);
    return discountAmount || 0;
  });
  interface SubscriptionResponse {
    subscription: Subscription;
  }
  const getSubscription = async (id: string, headers: HeadersType) => {
    try {
      const { subscription: subscriptionResult } =
        await nuxtApp.$apiFetch<SubscriptionResponse>(
          `/rest/subscription?subscriptionId=${id}`,
          {
            headers,
            credentials: 'include',
          }
        );

      subscription.value = subscriptionResult;
      return subscriptionResult;
    } catch (e) {
      return console.error('Error fetching subscription:', e);
    }
  };

  return {
    subscriptionDiscountPercentage: SUBSCRIPTION_DISCOUNT_PERCENTAGE,
    subscriptionProducts,
    subscriptionItemCount,
    addSubscriptionProduct,
    updateSubscriptionProductQuantity,
    removeSubscriptionProduct,
    subscriptionBaseAmount,
    subscriptionAmount,
    subscriptionCampaignAmount,
    subscriptionDiscountAmount,
    getSubscription,
    subscription,
    clearCart,
    loadSubscriptionProducts,
  };
});

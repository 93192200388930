import gql from 'graphql-tag';

const pricesFields = `
  price {
    value
    currencyCode
  }
  salePrice {
    value
    currencyCode
  }
  basePrice {
    value
    currencyCode
  }
`;

const productFragment = gql`
  fragment ProductFields on Product {
    entityId
    name
    path
    slug
    campaign {
      type
      amount
    }
    prices(includeTax: true) {
      ${pricesFields}
      isCheapestVariantPrice
    }
    defaultImage {
      url(width: 320)
      altText
    }
    variants {
      edges {
        node {
          entityId
          isPurchasable
          lowest30DaysPrice
          options {
            edges {
              node {
                entityId
                displayName
                values {
                  edges {
                    node {
                      entityId
                      label
                    }
                  }
                }
              }
            }
          }
          campaign {
            type
            amount
          }
          prices(includeTax: true) {
            ${pricesFields}
          }
          units
        }
      }
    }
    categoryIds
    isAvailable
  }
`;

const productExtendedFragment = gql`
  fragment ProductExtendedFields on Product {
    ...ProductFields
    description
    excerpt
    videos {
      id
      type
      videoId
    }
    variants {
      edges {
        node {
          entityId
          sku
          prices(includeTax: true) {
            ${pricesFields}
            pricePerKilo {
              value
            },
            pricePerLiter {
              value
            },
            pricePerUnit {
              value
            }
          }
          units
          isPurchasable
          lowest30DaysPrice
          options {
            edges {
              node {
                entityId
                displayName
                values {
                  edges {
                    node {
                      entityId
                      label
                    }
                  }
                }
              }
            }
          }
          inventory {
            aggregated {
              availableToSell
            }
            isInStock
          }
          bundle {
            unbundledPrice {
              value
              currencyCode
            }
            totalSavings {
              value
              currencyCode
            }
            items {
              name
              slug
              sku
              quantity
              price {
                value
                currencyCode
              }
              defaultImageUrl
              options {
                name
                nameId
                value
                valueId
              }
            }
          }
        }
      }
    }
    images {
      edges {
        node {
          altText
          url(width: 500, height: 500)
          urlOriginal
        }
      }
    }
    brand {
      entityId
      name
      path
      slug
    }
    inventory {
      isInStock
      aggregated {
        availableToSell
      }
      hasVariantInventory
    }
    categories {
      edges {
        node {
          entityId
          name
          path
          breadcrumbs(depth: 5) {
            edges {
              node {
                entityId
                name
                longName
                path
              }
            }
          }
        }
      }
    }
    mainCategoryId
    metaDesc
    pageTitle
    isAvailable
    isDeleted
    defaultVariant {
      entityId
      prices(includeTax: true) {
        ${pricesFields}
        pricePerKilo {
          value
        },
        pricePerLiter {
          value
        },
        pricePerUnit {
          value
        }
      }
      sku
    }
    storageClimate,
    isVetRecommended,
    animalCategories{
      node {
        entityId
        path
        animal
      }
    }
  }
  ${productFragment}
`;

const categoryFragment = gql`
  fragment CategoryFields on CategoryTreeItem {
    name
    entityId
    path
    menu
  }
`;

const cartFragment = gql`
  fragment CartFields on Cart {
    id
    metaFields{
      id
      key
      value
    },
    amount {
      value
      currencyCode
    }
    baseAmount {
      value
      currencyCode
    }
    campaignDiscountAmount {
      value
      currencyCode
    }
    promoDiscountAmount {
      value
      currencyCode
    }
    promo {
      code
      amount
      type
      isCombinable
      excludedBrands
    }
    items {
      id
      path
      name
      defaultImageUrl
      productId
      variantId
      options {
        name
        nameId
        value
        valueId
      }
      prices {
        ${pricesFields}
        promoPrice {
          value
          currencyCode
        }
      }
      quantity
      brand {
        entityId
        name
      }
      categories {
        entityId
        name
        path
        breadcrumbs(depth: 5) {
          edges {
            node {
              name
            }
          }
        }
      }
      primaryBreadcrumb
      animalType
      storageClimate
    }
    itemCount
    containsVetFood
    containsPrescriptionItem
    containsOtcItem
    storageClimates
    frozenItemsHandlingFee {
      value
      currencyCode
    }
  }
`;

export {
  productFragment,
  productExtendedFragment,
  categoryFragment,
  cartFragment,
};


// @ts-nocheck


export const localeCodes =  [
  "sv",
  "uk",
  "dk",
  "de",
  "us",
  "fi",
  "no"
]

export const localeMessages = {
  "sv": [{ key: "../languages/sv-SE.ts", load: () => import("../languages/sv-SE.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_sv_SE_ts" */), cache: true }],
  "uk": [{ key: "../languages/en-GB.ts", load: () => import("../languages/en-GB.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_en_GB_ts" */), cache: true }],
  "dk": [{ key: "../languages/da-DK.ts", load: () => import("../languages/da-DK.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_da_DK_ts" */), cache: true }],
  "de": [{ key: "../languages/de-DE.ts", load: () => import("../languages/de-DE.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_de_DE_ts" */), cache: true }],
  "us": [{ key: "../languages/en-US.ts", load: () => import("../languages/en-US.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_en_US_ts" */), cache: true }],
  "fi": [{ key: "../languages/fi-FI.ts", load: () => import("../languages/fi-FI.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_fi_FI_ts" */), cache: true }],
  "no": [{ key: "../languages/nb-NO.ts", load: () => import("../languages/nb-NO.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_nb_NO_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "Sweden",
      "code": "sv",
      "iso": "sv-SE",
      "dir": "ltr",
      "lang": "sv",
      "regionCode": "se",
      "files": [
        "languages/sv-SE.ts"
      ]
    },
    {
      "name": "United Kingdom",
      "code": "uk",
      "iso": "en-GB",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "uk",
      "files": [
        "languages/en-GB.ts"
      ]
    },
    {
      "name": "Denmark",
      "code": "dk",
      "iso": "da-DK",
      "dir": "ltr",
      "lang": "da",
      "regionCode": "dk",
      "files": [
        "languages/da-DK.ts"
      ]
    },
    {
      "name": "Germany",
      "code": "de",
      "iso": "de-DE",
      "dir": "ltr",
      "lang": "de",
      "regionCode": "de",
      "files": [
        "languages/de-DE.ts"
      ]
    },
    {
      "name": "United States",
      "code": "us",
      "iso": "en-US",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "us",
      "files": [
        "languages/en-US.ts"
      ]
    },
    {
      "name": "Finland",
      "code": "fi",
      "iso": "fi-FI",
      "dir": "ltr",
      "lang": "fi",
      "regionCode": "fi",
      "files": [
        "languages/fi-FI.ts"
      ]
    },
    {
      "name": "Norway",
      "code": "no",
      "iso": "nb-NO",
      "dir": "ltr",
      "lang": "nb",
      "regionCode": "no",
      "files": [
        "languages/nb-NO.ts"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./languages/",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://firstvet.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "name": "Sweden",
      "code": "sv",
      "iso": "sv-SE",
      "dir": "ltr",
      "lang": "sv",
      "regionCode": "se",
      "files": [
        {
          "path": "languages/sv-SE.ts"
        }
      ]
    },
    {
      "name": "United Kingdom",
      "code": "uk",
      "iso": "en-GB",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "uk",
      "files": [
        {
          "path": "languages/en-GB.ts"
        }
      ]
    },
    {
      "name": "Denmark",
      "code": "dk",
      "iso": "da-DK",
      "dir": "ltr",
      "lang": "da",
      "regionCode": "dk",
      "files": [
        {
          "path": "languages/da-DK.ts"
        }
      ]
    },
    {
      "name": "Germany",
      "code": "de",
      "iso": "de-DE",
      "dir": "ltr",
      "lang": "de",
      "regionCode": "de",
      "files": [
        {
          "path": "languages/de-DE.ts"
        }
      ]
    },
    {
      "name": "United States",
      "code": "us",
      "iso": "en-US",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "us",
      "files": [
        {
          "path": "languages/en-US.ts"
        }
      ]
    },
    {
      "name": "Finland",
      "code": "fi",
      "iso": "fi-FI",
      "dir": "ltr",
      "lang": "fi",
      "regionCode": "fi",
      "files": [
        {
          "path": "languages/fi-FI.ts"
        }
      ]
    },
    {
      "name": "Norway",
      "code": "no",
      "iso": "nb-NO",
      "dir": "ltr",
      "lang": "nb",
      "regionCode": "no",
      "files": [
        {
          "path": "languages/nb-NO.ts"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

